<template>
  <v-container>
    <v-form :readonly="!canEditSettings">
      <v-card>
        <v-card-title>Vehicle Owners</v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col cols="12" md="6" class="bg-blue">
              <v-subheader>Allow vehicles to be assigned to canceled trips?</v-subheader>
              <v-radio-group v-model="config.assignCanceled" row class="ml-4">
                <v-radio label="Yes" :value="true"></v-radio>
                <v-radio label="No" :value="false"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="6">
              <v-subheader>Number of minutes "gap" time between pickup and return time of vehicles</v-subheader>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="config.gapMinutes"
                  label="Gap Minutes"
                  outlined
                  type="number"
                  min="0"
                ></v-text-field>
              </v-col>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-form>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { GET_CONFIG, SET_CONFIG } from '@/store/modules/Config/actions';
import promptBeforeLeave from '@/mixins/promptBeforeLeave';
import { cloneDeep } from 'lodash';

export default {
  mixins: [promptBeforeLeave],
  inject: ['eventHub'],
  components: {},
  data: () => ({
    config: {
      assignCanceled: false,
      gapMinutes: 0,
    },
  }),
  computed: {
    ...mapGetters('user', ['me', 'canEditSettings']),
  },
  async mounted() {
    await this.fetchItems();
  },
  methods: {
    ...mapActions('config', [GET_CONFIG, SET_CONFIG]),
    async fetchItems() {
      this.vehicleOwnerConfig = await this.getConfig('vehicleOwner');
      this.config = { ...this.config, ...this.vehicleOwnerConfig };
      this.baseFormValue = cloneDeep(this.config);
    },
    async save() {
      try {
        const r = await this.setConfig({ name: 'vehicleOwner', value: this.config });
        if (r && r.done) {
          this.baseFormValue = cloneDeep(this.config);
          this.$myalert.success('Vehicle Owner config options saved');
        }
      } catch (error) {
        this.$myalert.error(error.message);
      }
    },
  },
  created() {
    this.eventHub.$on('saveVehicleOwnerConfig', () => this.save());
  },
  beforeDestroy() {
    this.eventHub.$off('saveVehicleOwnerConfig');
  },
  watch: {
    config: {
      handler(value) {
        this.latestFormValue = cloneDeep(value);
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.save-btn {
  float: right;
  margin-bottom: 12px;
}
.bg-blue {
  background-color: aliceblue;
}
.inline {
  display: inline-block;
}
.text-field {
  max-width: 100px;
}
/* .checkbox-wrapper {
  display: flex;
  align-items: center !important;
}
.checkbox {
  margin-right: auto;
} */
</style>
