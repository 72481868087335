var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c(
        "v-form",
        { attrs: { readonly: !_vm.canEditSettings } },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [_vm._v("Vehicle Owners")]),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "bg-blue",
                          attrs: { cols: "12", md: "6" },
                        },
                        [
                          _c("v-subheader", [
                            _vm._v(
                              "Allow vehicles to be assigned to canceled trips?"
                            ),
                          ]),
                          _c(
                            "v-radio-group",
                            {
                              staticClass: "ml-4",
                              attrs: { row: "" },
                              model: {
                                value: _vm.config.assignCanceled,
                                callback: function ($$v) {
                                  _vm.$set(_vm.config, "assignCanceled", $$v)
                                },
                                expression: "config.assignCanceled",
                              },
                            },
                            [
                              _c("v-radio", {
                                attrs: { label: "Yes", value: true },
                              }),
                              _c("v-radio", {
                                attrs: { label: "No", value: false },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "6" } },
                        [
                          _c("v-subheader", [
                            _vm._v(
                              'Number of minutes "gap" time between pickup and return time of vehicles'
                            ),
                          ]),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", md: "6" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "Gap Minutes",
                                  outlined: "",
                                  type: "number",
                                  min: "0",
                                },
                                model: {
                                  value: _vm.config.gapMinutes,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.config, "gapMinutes", $$v)
                                  },
                                  expression: "config.gapMinutes",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }